import { menus, site } from '~/config';
import Link from '~/components/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';

import { InsomniaLogoIcon } from '~/components/icons/insomnia-logo';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
};

export default function Footer({ className }: Props) {
  return (
    <footer className={twMerge('border-t border-gray-300 bg-white p-3 text-center text-[#000000a6]', className)}>
      <div className="mx-auto flex w-full max-w-7xl flex-col items-center justify-around space-y-5 md:flex-row md:space-x-0 md:space-y-0">
        <div className="flex h-7 flex-1 items-center justify-start">
          <InsomniaLogoIcon className="mx-[8px] h-[25px] w-[25px]" />
          <span className="mr-[5px] flex items-center gap-[5px]">
            <FontAwesomeIcon icon={faCopyright} className="flex h-3 w-3 items-center" />
            {new Date().getUTCFullYear()}
          </span>
          <Link
            className="text-[14px] text-[#000000a6] hover:text-primary hover:no-underline"
            to={site.copyrightURL}
            target="_blank"
          >
            {site.copyright}
          </Link>
        </div>
        <div className="flex max-w-7xl flex-1 justify-center space-x-2.5">
          {menus.footer &&
            menus.footer.center.map((item) => (
              <Link
                className="text-[14px] text-[#000000a6] hover:text-primary hover:no-underline"
                key={item.key}
                to={item.url}
                target="_blank"
              >
                {item.name}
              </Link>
            ))}
        </div>
        <div className="flex max-w-7xl flex-1 justify-end space-x-2.5">
          {menus.footer &&
            menus.footer.right.map((item) => (
              <Link
                className="text-[14px] text-[#000000a6] hover:text-primary hover:no-underline"
                key={item.key}
                to={item.url}
                target="_blank"
              >
                {item.name}
              </Link>
            ))}
        </div>
      </div>
    </footer>
  );
}
